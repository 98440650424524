<template>
	<div class="view">
		<ArticleList
			:list="podcasts"
		/>
	</div>
</template>

<script>
import ArticleList from '@/components/ArticleList.vue'
export default {
	name: 'PodcastsView',
	components: {ArticleList},
	data() {
		return {
			podcasts: []
		}
	},

	mounted() {
		fetch(process.env.VUE_APP_URL_API + 'api/article/list', {
			method: 'GET',
			headers: new Headers({
				'Authorization': 'Basic '+btoa('tita_user:ef4515ee-5e13-423d-89f1-a2f4730ad346'), 
				"Content-Type": "application/json",
			}),
		})
			.then(response => response.json())
			.then(data => this.podcasts = data.filter(art => art.articleTypeCode == 'POD' && art.state == 'PUBLISHED'))
			.catch(error => console.error('Error:', error));
	},
}
</script>