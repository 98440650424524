<template>
	<div class="view ac-step">
		<div class="date-form display-contents" v-if="!showSuccess">
			<h2>¿Te hiciste el test de VPH?</h2>
			<p>Agendá la fecha y recibí un recordatorio para que vayas a retirarlo.</p>
			<div class="form">
				<VueDatePicker
					v-model="date"
					:max-date="new Date()"
					:enable-time-picker="false"
					:select-text="'Aplicar'"
					:cancel-text="'Cancelar'"
					:locale="'es-AR'"
					:day-names="['dom', 'lun', 'mar', 'mie', 'jue', 'vie', 'sab']"
				/>
			</div>
			<div class="btn-container flex-v">
				<button
					type="button"
					class="btn btn-primary btn-lg"
					:disabled="!date"
					@click="submitDate()"
					>
						Agendar
				</button>
				<button
					type="button"
					class="btn btn-secondary btn-lg"
					@click="submitDate()"
					>
						Omitir
				</button>
			</div>
		</div>
		<div class="success flex-v" v-else>
			<div class="msg">
				<div class="img-container">
					<img :src="require(`@/assets/img/success.svg`)" alt="">
				</div>
				<h2>¡Agendado!</h2>
				<p>Podrás ver tu recordatorio en la agenda y modificar la fecha si lo necesitás.</p>
			</div>
			<div class="btn-container">
				<button class="btn btn-primary btn-lg" type="button" @click="nextStep()">Siguiente</button>
			</div>
		</div>
	</div>
</template>

<script>
import messages from '@/lang/es.js'
import VueDatePicker from '@vuepic/vue-datepicker'
import '@vuepic/vue-datepicker/dist/main.css'

export default {
	name: 'FechaTest',
	data() {
		return {
			date: undefined,
			showSuccess: false
		}
	},

	components: {
		VueDatePicker
	},

	methods: {
    submitDate() {
			this.$emit('submit', 'lastTestDate', this.date)

			if (this.date !== undefined) {
				this.showSuccess = true
			}

			else {
				this.nextStep()
			}
    },

		nextStep() {
			this.$router.push({name: 'Email'})
			this.$emit('step-change', 4)
		}
  },

	created() {
		this.msg = messages
	},

	mounted() {		
    this.$emit('step-change', 3);
  },
}
</script>