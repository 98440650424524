<template>
	<div class="view articles flex-v gap24">
		<router-link
			:to="{
				name: 'Enviar pregunta'
			}"
		>
			enviar pregunta
		</router-link>
		<router-link
			:to="{
				name: 'Preguntas enviadas'
			}"
		>
			ver preguntas enviadas
		</router-link>
		<p>artículos:</p>
		<ArticleList
			:list="articles"
		>
		</ArticleList>
	</div>
</template>

<script>
import ArticleList from '@/components/ArticleList.vue'
export default {
	name: 'QuestionsView',
	components: {ArticleList},
	data() {
		return {
			articles: []
		}
	},

	mounted() {
		fetch(process.env.VUE_APP_URL_API + 'api/article/list', {
			method: 'GET',
			headers: new Headers({
				'Authorization': 'Basic '+btoa('tita_user:ef4515ee-5e13-423d-89f1-a2f4730ad346'), 
				"Content-Type": "application/json",
			}),
		})
		.then(response => response.json())
		.then(data => this.articles = data.filter(art => art.articleTypeCode == 'PRE' && art.state == 'PUBLISHED'))
		.catch(error => console.error('Error:', error))
	},
}
</script>