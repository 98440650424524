<template>
	<div class="slide" :data-order="slide.order">
		<div class="header-image" v-if="slide.image">
			<img :src="slide.image" alt="">
		</div>
		<div class="content">
			<p class="h1">{{ slide.title }}</p>
			<p class="body-1" v-html="slide.content"></p>
			<TermsCheck
				v-if="slide.order == 5"
			/>
		</div>
	</div>
</template>

<script>
import messages from '@/lang/es.js'
import TermsCheck from '@/components/TermsCheck.vue'

export default {
	name: 'WTSlide',
	props: ['slide'],
	data() {
		return {
			msg: {}
		}
	},

	emits: ['termsAccepted'],
	components: { TermsCheck },

	created() {
		this.msg = messages
	}
}
</script>

<style scoped>
.slide {
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding-top: 64px;
	color: var(--color-n80);
}

.header-image {
	margin-bottom: 24px;
	height: 250px;
}

img {
	max-height: 100%;
}

.content {
	text-align: center;
}

.h1 {
	color: var(--color-p40);
	margin-bottom: 8px;
}

[data-order="5"] {
	padding-top: 0;
}

[data-order="5"] .h1 {
	color: var(--color-n100);
	text-align: left;
	font-size: var(--type-h2-size);
	font-weight: var(--type-h2-weight);
}

[data-order="5"] .content {
	text-align: left;
	height: 100%;
	display: flex;
	flex-direction: column;
}

.slide[data-order="5"] .body-1 {
	display: flex;
	flex-direction: column;
	gap: 1em;
}
</style>