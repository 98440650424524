<template>
	<div class="view ac-step">
		<div class="pin-form flex-v">
			<h2>¡Último paso! Creá una clave de seguridad</h2>
			<p>Utilizarás esta clave para desbloquear la app cada vez que ingreses.</p>
			<PinInputGroup
				:pinLength="pinLength"
				@valid="onValidPinEntered"
				@invalid="onInvalidPinEntered"
			/>
			<div class="btn-container flex-v">
				<button
					class="btn btn-primary btn-lg"
					@click="submitPin"
					:disabled="!pinValid"
				>
					Siguiente
				</button>
			</div>
		</div>
	</div>
</template>

<script>
import PinInputGroup from '../PinInputGroup.vue';
import { useAppStore } from '@/stores/index.js'

export default {
	name: 'ACPin',
	emits: ['submit'],
	
	data() {
		return {
			done: false,
			pinLength: 4,
			pin: undefined,
			pinValid: false,
			resetPassword: false
		}
	},

	components: {PinInputGroup},

	methods: {
		onInvalidPinEntered() {
			this.pinValid = false
			this.pin = undefined
		},

		onValidPinEntered(input) {
			this.pinValid = true
			this.pin = input
		},

		submitPin() {
			if (useAppStore().resetPassword) {
				console.log("entro");
				fetch(process.env.VUE_APP_URL_API + 'api/user/updatePin', {
					method: 'POST',
					headers: new Headers({
						'Authorization': 'Basic '+btoa('tita_user:ef4515ee-5e13-423d-89f1-a2f4730ad346'), 
						"Content-Type": "application/json",
					}),
					body: JSON.stringify({cuid: useAppStore().accountData.cuid, password: this.pin})
				})
				.then(response => response.json())
				.then(() => {
				// Redirige al usuario y luego establece resetPassword como false
				useAppStore().setResetPassword(false); // Llama a la acción en el store
				console.log("Pipa resetPasswordFalse" + useAppStore().resetPassword);
				this.$router.push({ name: 'Login' });
				})
				.catch(error => console.error('Error:', error));
			}

			else {
				this.$emit('submit', 'password', this.pin)
			}
		},
	},

	created() {
		// if (this.$route.params?.resetPassword) {
		// 	this.resetPassword = true
		// }
	},

	mounted() {
		this.$emit('step-change', 5)
	},
}
</script>