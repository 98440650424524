import { createApp } from 'vue'
import { useAppStore } from './stores/index.js'
import { createPinia } from 'pinia'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import messages from '@/lang/es.js'

const pinia = createPinia()
const app = createApp(App)
app.use(pinia).use(router)
app.mount('#app')

const appStore = useAppStore();
appStore.$subscribe((mutation, state) => {
  localStorage.setItem('tita-acct', JSON.stringify(state.accountData));
})

const storedData = localStorage.getItem('tita-acct');
if (storedData) {
	appStore.$patch({accountData: JSON.parse(storedData)});
}
app.config.globalProperties.messages = messages
