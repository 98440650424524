<template>
	<div class="wrapper">
		<div class="view" id="recover-password">
			<div class="view-content flex-v" v-if="!emailSent">
				<h2>Recuperar clave</h2>
				<p>Indicanos tu email y te enviaremos un código de seguridad para recuperar tu clave.</p>
				<div class="form">
					<div class="form-field-vertical">
						<label>Dirección de email</label>
						<input type="email" v-model="email">
					</div>
				</div>
				<div class="btn-container flex-v">
					<button
						class="btn btn-primary btn-lg"
						:disabled="!isEmailValid"
						@click="verifyEmail()"
					>
						Siguiente
					</button>
				</div>
			</div>
			<div class="confirm flex-v" v-if="emailSent && !emailVerified">
				<div class="msg">
					<div class="img-container">
						<img :src="require(`@/assets/img/mensaje.gif`)" alt="">
					</div>
					<div class="msg-text">
						<h2>¿Recibiste el email?</h2>
						<p>Te enviamos un correo a {{ email }} para confirmar tu dirección.</p>
						<p>Si no lo recibiste, mirá en spam.</p>
					</div>
				</div>
				<div class="btn-container flex-v">
					<button 
						class="btn btn-secondary btn-lg"
						type="button"
						:disabled="countdown > 0"
					>
						Reenviar mail{{ countdown > 0 ? ` en 00:${paddedCountdown}` : ''}}
					</button>
				</div>
			</div>
			<div class="new-password flex-v" v-if="emailSent && emailVerified">
				<h2>Creá una nueva clave de seguridad</h2>
				<p>Utilizarás esta clave para desbloquear la app cada vez que ingreses.</p>
				<PinInputGroup
					:pinLength="pinLength"
					@valid="onValidPinEntered"
					@invalid="onInvalidPinEntered"
				/>
				<div class="btn-container">
					<button
						class="btn btn-primary btn-lg"
						@click="submitNewPin"
						:disabled="!pinValid"
					>
						Siguiente
					</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { useAppStore } from '@/stores'
import PinInputGroup from '@/components/PinInputGroup.vue'

export default {
	name: 'RecoverPassword',
	components: {PinInputGroup},
	data() {
		return {
			email: undefined,
			emailSent: false,
			emailVerified: false,
			emailRegex: /^([a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+)$/,
			countdown: 45,
			pinLength: 4,
			pin: undefined,
			pinValid: false
		}
	},

	computed: {
		isEmailValid() {
			return this.email?.length > 1 
			&& this.email?.match(this.emailRegex)?.length > 0
		},

		paddedCountdown() {
			return this.countdown < 10 ? '0' + this.countdown : this.countdown
		}
	},

	methods: {
		submitNewPin() {
			fetch(process.env.VUE_APP_URL_API + 'api/user/updatePassword', {
				method: 'POST',
				headers: new Headers({
					'Authorization': 'Basic '+btoa('tita_user:ef4515ee-5e13-423d-89f1-a2f4730ad346'), 
					"Content-Type": "application/json",
				}), 
				body: JSON.stringify({
					email: this.email,
					token: useAppStore().accountData.token,
					password: this.pin
				})
			})
			.then(response => response.json())
				.then(data => {if (data.code == 'SUCCESS') {
					useAppStore().setAccountProperty('password', this.pinCode)
					this.$router.push({name: 'Login'})
				}})
				.catch(error => console.error('Error:', error));
		},

		verifyEmail() {
			fetch(process.env.VUE_APP_URL_API + 'api/user/isValidEmail?resetPassword=true', {
				method: 'POST',
				headers: new Headers({
					'Authorization': 'Basic '+btoa('tita_user:ef4515ee-5e13-423d-89f1-a2f4730ad346'), 
					"Content-Type": "application/json",
				}), 
				body: JSON.stringify({
					email: this.email,
					token: useAppStore().accountData.token, 
					cuid: useAppStore().accountData.cuid
				})
			})
			.then(response => response.json())
				.then(data => {if (data.code == 'SUCCESS') {
					this.emailSent = true
					this.startTimer()
				}})
				.catch(error => console.error('Error:', error));
		},

		startTimer() {
			const timer = setInterval(() => {
        if (this.countdown > 0) {
          this.countdown--;
        } else {
          clearInterval(timer);
        }
      }, 1000);
		},

		onInvalidPinEntered() {
			this.pinValid = false
			this.pin = undefined
		},

		onValidPinEntered(input) {
			this.pinValid = true
			this.pin = input
			this.login()
		},
	},

	mounted() {
		if (this.$route.query.cuid) {
			this.emailVerified = true
			this.emailSent = true
		}
	}
}
</script>

<style scoped>
.wrapper,
.view-content {
	height: 100%;
}

.form {
	margin-top: 24px;
}
</style>