<template>
  <div class="view home">
		<h1>Hola, {{ name }}!</h1>
		<!-- <router-link :to="{name: 'Información'}">Información (todos los artículos)</router-link> -->
		<router-link :to="{name: 'Preguntas y respuestas'}">Preguntas y respuestas</router-link>
		<router-link :to="{name: 'Test y controles'}">Test y controles</router-link>
		<router-link :to="{name: 'Podcasts'}">Podcasts</router-link>
		<router-link :to="{name: 'Testimonios'}">Testimonios</router-link>
  </div>
</template>

<script>
import { useAppStore } from '@/stores/index.js'
export default {
  name: 'HomeView',
	data() {
		return {
			name: useAppStore().accountData.nickname
		}
	}
}
</script>

<style>
.home {
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	padding: 3em;
}
</style>
