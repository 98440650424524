<template>
	<div id="account-creation" class="walkthrough" v-if="!accountCreated">
		<LoadingSpinner
			v-if="loading"
		/>
		<router-view
			v-if="!loading"
			@step-change="updateStep"
			@submit="updateData"
			@reregister="reregisterAccount"
		/>
	</div>
	<div class="view ac-step success" v-else>
		<div class="msg">
			<div class="img-container">
				<img :src="require(`@/assets/img/success.svg`)" alt="">
			</div>
			<div class="msg-text">
				<h1>¡Comenzá a usar la app!</h1>
				<p>Completaste el registro de Tita.</p>
			</div>
		</div>
		<div class="btn-container">
			<button class="btn btn-primary btn-lg" type="button" @click="login()">Comencemos</button>
		</div>
	</div>
	<div class="view ac-step error" v-if="error">
		<div class="msg">
			<div class="img-container">
				<img :src="require(`@/assets/img/error.svg`)" alt="">
			</div>
			<div class="msg-text">
				<h1>Error</h1>
				<p>El mail ya se encuentra registrado</p>
			</div>
		</div>
		<div class="btn-container">
			<button class="btn btn-primary btn-lg" type="button" @click="this.$router.push({name: 'ACEmail'})">Volver</button>
		</div>
	</div>
</template>

<script>
import LoadingSpinner from '@/components/LoadingSpinner.vue'
import { useAppStore } from '@/stores/index.js'

export default {
	name: 'AccountCreationView',
	data() {
		return {
			loading: false,
			totalSteps: 5,
			error: false
		}
	},

	components: {
		LoadingSpinner
	},

	computed: {
		accountCreated() {
			return useAppStore().accountCreation.accountCreated
		}
	},
	
  methods: {
		createAccount() {
			this.loading = true			
			fetch(process.env.VUE_APP_URL_API + `api/user/new`, {
				method: 'POST',
				headers: new Headers({
					'Authorization': 'Basic '+btoa('tita_user:ef4515ee-5e13-423d-89f1-a2f4730ad346'), 
					"Content-Type": "application/json",
				}), 
				body: JSON.stringify(useAppStore().accountData)
			})
				.then(response => response.json())
				.then(response => this.onAccountCreated(response))
				.catch(error => console.error('Error:', error));
		},

		login() {
			this.$router.push({name: 'Login'})
		},

		onAccountCreated(data) {
			this.loading = false
			if (data.message == 'Error: El mail ya se encuentra registrado') {
				this.error = true
			}

			else {
				useAppStore().accountCreation.accountCreated = true
				useAppStore().showNavBack = false
				// useAppStore().clearAccountData()
				useAppStore().setAccountProperty('token', data.token)
				useAppStore().setAccountProperty('name', data.name)
				useAppStore().setAccountProperty('email', data.email)
			}
		},

		reregisterAccount() {
			const tempData = useAppStore().getTempData()
			console.log(tempData)
			this.onAccountCreated(tempData)
		},

		updateData(key, value) {
			useAppStore().accountData[key] = value
			if ((this.currentStep == this.totalSteps) && this.$route.query?.reregister !== 'true') {
				this.createAccount()
			}
		},

    updateStep(step) {
      this.currentStep = step;
			this.$emit('onUpdateStep', step)
    }
  },

	created() {
		// if (this.$route.query?.reregister == true) {
		// 	console.log('sarasa')
		// }
	},

	mounted() {
	}
}
</script>

<style>
	#account-creation,
	.email-form,
	.pin-form,
	.confirm,
	.success,
	.error {
		height: 100%;
	}

	#account-creation {
		display: flex;
		flex-direction: column;
	}

	.ac-step {
		flex-grow: 1;
		display: flex;
    flex-direction: column;
		padding: 24px;
	}

	.top-menu:has(~.ac-step > .confirm, 
		+.ac-step.success,
		+.ac-step.error,
		+.ac-step .loader) {
		visibility: hidden;
	}

	.ac-step .form {
		display: flex;
		flex-direction: column;
		gap: 32px;
		margin-top: 32px;
	}

	.form input {
		width: 100%;
	}

	.form-field-vertical label {
		color: var(--color-n80);
	}

	.msg {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
		padding-top: 24px;
	}

	.msg .img-container {
		margin-bottom: 40px;
	}

	.msg img {
		max-width: 9em;
	}
</style>