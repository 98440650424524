<template>
	<div class="view">
		<h1>Notificaciones</h1>
	</div>
</template>

<script>
export default {
	name: 'NotificationsView'
}
</script>