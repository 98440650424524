<template>
	<div class="form-field flex-h">
		<input type="checkbox" id="acceptTerms" v-model="this.termsAccepted" @change="$parent.$emit('termsAccepted', this.termsAccepted)">
		<label for="acceptTerms" class="body-small green">{{ msg.walkthrough.acceptTerms }}</label>
	</div>
	<!-- <div class="btn-container flex-center">
		<button class="btn btn-primary btn-lg" :disabled="!this.termsAccepted" @click="acceptTerms">{{ msg.common.next }}</button>
	</div> -->
</template>

<script>
import messages from '@/lang/es.js'
export default {
	name: 'TermsCheck',
	data() {
		return {
			msg: {},
			termsAccepted: false,
		}
	},
	created() {
		this.msg = messages
	}
}
</script>

<style scoped>
.form-field {
	margin-top: 24px;
	display: flex;
	gap: 8px;
}
</style>