<template>
	<div class="bottom-nav">
		<div class="nav-links">
			<router-link
				:to="{name: 'home'}"
				class="nav-link nav-link-home"
				active-class="active"
				aria-label="Inicio"
			>
				<svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path fill-rule="evenodd" clip-rule="evenodd" d="M13.3494 1.40805C13.0785 1.1974 12.6993 1.1974 12.4285 1.40805L3.42846 8.40805C3.24577 8.55014 3.13892 8.76862 3.13892 9.00006V20.0001C3.13892 20.7294 3.42865 21.4289 3.94437 21.9446C4.4601 22.4603 5.15957 22.7501 5.88892 22.7501H19.8889C20.6183 22.7501 21.3177 22.4603 21.8335 21.9446C22.3492 21.4289 22.6389 20.7294 22.6389 20.0001V9.00006C22.6389 8.76862 22.5321 8.55014 22.3494 8.40805L13.3494 1.40805ZM4.63892 20.0001V9.36687L12.8889 2.95021L21.1389 9.36687V20.0001C21.1389 20.3316 21.0072 20.6495 20.7728 20.8839C20.5384 21.1184 20.2204 21.2501 19.8889 21.2501H16.6389V12C16.6389 11.5858 16.3031 11.25 15.8889 11.25H9.88892C9.4747 11.25 9.13892 11.5858 9.13892 12V21.2501H5.88892C5.5574 21.2501 5.23945 21.1184 5.00503 20.8839C4.77061 20.6495 4.63892 20.3316 4.63892 20.0001ZM10.6389 21.2501H15.1389V12.75H10.6389V21.2501Z" fill="#474747"/>
				</svg>
			</router-link>
			<router-link
				:to="{name: 'Notificaciones'}"
				class="nav-link nav-link-notifications has-notifications"
				active-class="active"
				aria-label="Notificaciones"
			>
				<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M6 8C6 6.4087 6.63214 4.88258 7.75736 3.75736C8.88258 2.63214 10.4087 2 12 2C13.5913 2 15.1174 2.63214 16.2426 3.75736C17.3679 4.88258 18 6.4087 18 8C18 15 21 17 21 17H3C3 17 6 15 6 8Z" stroke="#474747" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
					<path d="M10.3 21C10.4674 21.3044 10.7135 21.5583 11.0125 21.7352C11.3115 21.912 11.6526 22.0053 12 22.0053C12.3474 22.0053 12.6885 21.912 12.9875 21.7352C13.2866 21.5583 13.5326 21.3044 13.7 21" stroke="#474747" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
				</svg>
			</router-link>
			<router-link
				:to="{name: 'Agenda'}"
				class="nav-link"
				active-class="active"
				aria-label="Agenda"
			>
				<svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M8.88892 2V6" stroke="#474747" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
					<path d="M16.8889 2V6" stroke="#474747" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
					<path d="M21.8889 17V6C21.8889 5.46957 21.6782 4.96086 21.3031 4.58579C20.9281 4.21071 20.4193 4 19.8889 4H5.88892C5.35848 4 4.84978 4.21071 4.4747 4.58579C4.09963 4.96086 3.88892 5.46957 3.88892 6V20C3.88892 20.5304 4.09963 21.0391 4.4747 21.4142C4.84978 21.7893 5.35848 22 5.88892 22H16.8889L21.8889 17Z" stroke="#474747" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
					<path d="M3.88892 10H21.8889" stroke="#474747" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
					<path d="M15.8889 22V18C15.8889 17.4696 16.0996 16.9609 16.4747 16.5858C16.8498 16.2107 17.3585 16 17.8889 16H21.8889" stroke="#474747" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
				</svg>
			</router-link>
			<router-link
				:to="{name: 'Información'}"
				class="nav-link"
				active-class="active"
				aria-label="Información"
			>
				<svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M4.55554 19.5V4.5C4.55554 3.83696 4.81893 3.20107 5.28778 2.73223C5.75662 2.26339 6.3925 2 7.05554 2H20.5555V22H7.05554C6.3925 22 5.75662 21.7366 5.28778 21.2678C4.81893 20.7989 4.55554 20.163 4.55554 19.5ZM4.55554 19.5C4.55554 18.837 4.81893 18.2011 5.28778 17.7322C5.75662 17.2634 6.3925 17 7.05554 17H20.5555" stroke="#474747" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
					<path d="M8.55554 7H14.5555" stroke="#474747" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
					<path d="M8.55554 11H16.5555" stroke="#474747" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
				</svg>
			</router-link>
			<router-link
				:to="{name: 'Testimonios'}"
				class="nav-link"
				active-class="active"
				aria-label="Testimonios"
			>
				<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M21 15C21 15.5304 20.7893 16.0391 20.4142 16.4142C20.0391 16.7893 19.5304 17 19 17H7L3 21V5C3 4.46957 3.21071 3.96086 3.58579 3.58579C3.96086 3.21071 4.46957 3 5 3H19C19.5304 3 20.0391 3.21071 20.4142 3.58579C20.7893 3.96086 21 4.46957 21 5V15Z" stroke="#474747" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
					<path d="M8 12C8.53043 12 9.03914 11.7893 9.41421 11.4142C9.78929 11.0391 10 10.5304 10 10V8H8" stroke="#474747" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
					<path d="M14 12C14.5304 12 15.0391 11.7893 15.4142 11.4142C15.7893 11.0391 16 10.5304 16 10V8H14" stroke="#474747" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
				</svg>
			</router-link>
		</div>
	</div>
</template>

<script>
export default {
	name: 'BottomNav'
}
</script>

<style scoped>
.bottom-nav {
	--links-amount: 5;
	--link-width: calc(100% / var(--links-amount));
	--active-color: var(--color-g40);
	height: var(--bottom-nav-height);
	width: 100%;
	position: fixed;
	bottom: 0;
	background: var(--color-n0);
	box-shadow: 0 -1px 4px 0 rgba(71, 71, 71, 0.05);
}

.nav-links {
	height: 100%;
	display: flex;
}

.nav-link {
	width: var(--link-width);
	height: 100%;
	display: grid;
	place-content: center;
	border: 0;
	position: relative;
}

.nav-link svg path {
	transition: fill .3s ease;
}

.nav-link::after {
	--underline-width: 8px;
	content: '';
	width: var(--underline-width);
	height: 1px;
	background-color: var(--active-color);
	position: absolute;
	bottom: 23px;
	left: calc(50% - (var(--underline-width) / 2));
	border-radius: 100px;
	transform: scaleX(0);
	transition: transform .3s ease;
}

.nav-link:not(.nav-link-home).active svg path {
	stroke: var(--active-color);
}

.nav-link.nav-link-home.active svg path {
	fill: var(--active-color);
}

.nav-link.active::after {
	transform: scaleX(1);
}

.has-notifications::before {
	content: '';
	width: 6px;
	height: 6px;
	border-radius: 50%;
	background: var(--color-o50);
	position: absolute;
	top: 30px;
	right: 27px;
	border: 2px solid var(--color-n0);
}
</style>