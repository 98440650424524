<template>
	<div
		v-if="list.length > 0"
		class="article-list scrollable-list flex-v gap24"
	>
		<div
			v-for="article in list"
			class="article-card"
			:key="article.articleId"
		>
			<h3>{{ article.title }}</h3>
			<p v-html="article.subtitle"></p>
			<router-link :to="{ name: 'ArticleView', params: { id: article.articleId } }">leer artículo</router-link>
		</div>
	</div>
	<div
		v-else
		class="msg"
	>
		<p class="">No hay contenidos cargados.</p>
	</div>
</template>

<script>
export default {
	name: 'ArticleList',
	props: ['list']
}
</script>