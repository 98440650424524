<template>
	<div class="view">
		<h1>Agenda</h1>
	</div>
</template>

<script>
export default {
	name: 'CalendarView'
}
</script>