<template>
	<div class="view ac-step">
		<LoadingSpinner
			v-if="emailSubmitted && !emailSent && !emailVerified && !error"
		/>
		<div class="email-form flex-v" v-if="!emailSubmitted && !emailSent && !error">
			<h2>¿Cuál es tu email?</h2>
			<p>Una vez ingresado tendrás que validarlo.</p>
			<div class="form">
				<div class="form-field-vertical">
					<label>Tu email</label>
					<input
						type="email"
						placeholder="usuaria@gmail.com"
						v-model="email"
						:valid="isEmailInputValid"
						:invalid="isEmailInputInvalid"
						@keyup.enter="focusNextInput()"
					>
				</div>
				<div class="form-field-vertical">
					<label>Repetí tu email</label>
					<input
						type="email"
						ref="confirm-email"
						placeholder="usuaria@gmail.com"
						:valid="isEmailRepeatValid"
						:invalid="isEmailRepeatInvalid"
						v-model="repeatEmail"
						@keyup.enter="isValid ? verifyEmail() : false"
					>
					<span class="hint" v-if="showEmailRepeatError">Los dos campos deben ser iguales</span>
				</div>
			</div>
			<div class="btn-container flex-v">
				<button
					class="btn btn-primary btn-lg"
					:disabled="!isEmailRepeatValid"
					@click="verifyEmail"
				>
					Siguiente
				</button>
				<!-- SOLO PARA PRUEBAS MIENTRAS NO ANDE MANDAR MAILS DESDE LINODE -->
				<!-- <button
					class="btn btn-primary btn-lg"
					:disabled="!isValid"
					@click="bypassVerification"
				>
					Siguiente
				</button> -->
			</div>
		</div>
		<div class="confirm flex-v" v-if="emailSent && !emailVerified & !error">
			<div class="msg">
				<div class="img-container">
					<img :src="require(`@/assets/img/mensaje.gif`)" alt="">
				</div>
				<div class="msg-text">
					<h2>¿Recibiste el email?</h2>
					<p>Te enviamos un correo a {{ email }} para {{ reregister ? 'validar tu identidad' : 'confirmar tu dirección' }}.</p>
				</div>
			</div>
			<p class="body-small">
				<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0_98_46)"><path d="M7.99992 14.6666C11.6818 14.6666 14.6666 11.6819 14.6666 7.99998C14.6666 4.31808 11.6818 1.33331 7.99992 1.33331C4.31802 1.33331 1.33325 4.31808 1.33325 7.99998C1.33325 11.6819 4.31802 14.6666 7.99992 14.6666Z" stroke="#474747" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path><path d="M8 10.6667V8" stroke="#474747" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path><path d="M8 5.33331H8.00667" stroke="#474747" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path></g><defs><clipPath id="clip0_98_46"><rect width="16" height="16" fill="white"></rect></clipPath></defs></svg>
					Si no lo recibiste, mirá en spam.
			</p>
			<div class="btn-container flex-v">
				<button 
					class="btn btn-secondary btn-lg"
					type="button"
					:disabled="countdown > 0"
					@click="resendEmail"
				>
					Reenviar mail{{ countdown > 0 ? ` en 00:${paddedCountdown}` : ''}}
				</button>
			</div>
		</div>
		<div class="success flex-v" v-if="emailVerified && !error">
			<div class="msg">
				<div class="img-container">
					<img :src="require(`@/assets/img/success.svg`)" alt="">
				</div>
				<h2>¡Confirmaste tu email!</h2>
				<p>Esto es importante para recuperar tu contraseña si lo necesitás.</p>
			</div>
			<div class="btn-container">
				<button class="btn btn-primary btn-lg" type="button" @click="nextStep()">Siguiente</button>
			</div>
		</div>
		<div class="error flex-v" v-if="error">
			<div class="msg">
				<div class="img-container">
					<img :src="require(`@/assets/img/error.svg`)" alt="">
				</div>
				<h2>Mail inválido</h2>
				<p>Ya existe una cuenta registrada con este mail. Tendrás que utilizar uno diferente.</p>
			</div>
			<div class="btn-container">
				<button class="btn btn-primary btn-lg" type="button" @click="resetEmailForm()">Reintentar</button>
			</div>
		</div>
		<div class="timeout-error flex-v" v-if="timeout">
			<div class="msg">
				<div class="img-container">
					<img :src="require(`@/assets/img/error.svg`)" alt="">
				</div>
				<h2>Tu tiempo expiró</h2>
				<p>Reenviá el mail para intentarlo nuevamente.</p>
			</div>
			<p>Si no lo recibiste, mirá en spam.</p>
			<div class="btn-container">
				<button class="btn btn-primary btn-lg" type="button" @click="verifyEmail">Reenviar email</button>
			</div>
		</div>
	</div>
</template>

<script setup>
import { useAppStore } from '@/stores/index.js'
</script>
<script>
import LoadingSpinner from '@/components/LoadingSpinner.vue'

export default {
	name: 'ACEmail',
	data() {
		return {
			email: undefined,
			repeatEmail: undefined,
			emailSubmitted: false,
			emailSent: false,
			emailVerified: false,
			emailRegex: /^([a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+)$/,
			countdown: 45,
			error: false,
			timeout: false,
			reregister: false,
			recoverPassword: false
		}
	},

	components: {LoadingSpinner},

	computed: {
		isEmailInputValid() {
			if (this.email?.length > 0  && this.email?.match(this.emailRegex)?.length > 0) {
				return true
			}

			else return null
		},

		isEmailInputInvalid() {
			if (this.email?.length > 0  && !this.email?.match(this.emailRegex)) {
				return true
			}

			else return null
		},

		isEmailRepeatValid() {
			if (this.email?.length > 0 
			&& this.repeatEmail?.length > 0 
			&& this.email == this.repeatEmail) {
				return true
			}

			else return null
		},

		isEmailRepeatInvalid() {
			if (this.email?.length > 0 && this.repeatEmail?.length > 0 && this.email !== this.repeatEmail) {
				return true
			}

			else {
				return null
			}
		},

		showEmailRepeatError() {
			if (this.email?.length > 0
				&& this.repeatEmail?.length > 0 
				&& this.email !== this.repeatEmail) {
				return true
			}

			else return false
		},

		paddedCountdown() {
			return this.countdown < 10 ? '0' + this.countdown : this.countdown
		}
	},

	methods: {
		focusNextInput() {
			this.$refs.input.focus()
		},
		
		nextStep() {
			if (this.reregister) {
				var tempData = useAppStore().getTempData()
				delete tempData.password
				useAppStore().accountData = tempData
				useAppStore().accountCreation.accountCreated = true
			}

			else if (this.recoverPassword && this.emailVerified) {
				this.$router.push({name: 'PIN', params: { resetPassword: true}})
			}

			else {
				this.$router.push({name: 'PIN', query: { reregister: this.$route.query?.reValid == 'true' ? true : false}})
				this.$emit('step-change', 5)
			}
		},

		onVerifyEmail(response) {
			if (response.code == 'VALID_EMAIL' || response.code == 'INVALID_EMAIL' && this.reregister || this.recoverPassword) {
				this.emailSent = true
			}

			else if (response.code == 'EXPIRED_EMAIL') {
				this.timeout = true
			}

			else if (response.code == 'INVALID_EMAIL' && !this.reregister) {
				this.error = true
			}
		},

		onVerifyThroughLink() {
			this.$emit('submit', 'email', this.$route.query.email)
			this.$emit('submit', 'hasValidEmail', true)
			this.emailSent = true
			this.emailVerified = true
		},

		resendEmail() {
			this.countdown = 45
			this.verifyEmail()
		},

		resetEmailForm() {
			this.error = false
			this.loading = false
			this.emailSubmitted = false
			this.email = ''
			this.repeatEmail = ''
		},

		// SOLO PARA PRUEBAS MIENTRAS NO ANDE MANDAR MAILS DESDE LINODE
		// bypassVerification() {
		// 	this.emailSubmitted = true
		// 	this.error = false
		// 	this.timeout = false
		// 	this.$emit('submit', 'email', this.email)
		// 	this.$emit('submit', 'hasValidEmail', true)
		// 	this.emailSent = true
		// 	this.emailVerified = true
		// },

		verifyEmail() {
			this.startTimer()
			this.emailSubmitted = true
			this.error = false
			this.timeout = false
			var params = this.recoverPassword ? '?resetPassword=true' : ''

			fetch(process.env.VUE_APP_URL_API + `api/user/isValidEmail${params}` , {
				method: 'POST',
				headers: new Headers({
					'Authorization': 'Basic '+btoa('tita_user:ef4515ee-5e13-423d-89f1-a2f4730ad346'), 
					"Content-Type": "application/json",
				}), 
				body: JSON.stringify({
					email: this.email,
					cuid: useAppStore().accountData.cuid
				})
			})
			.then(response => response.json())
			.then(response => this.onVerifyEmail(response))
			.catch(error => console.error('Error:' + error))
		},

		startTimer() {
			const timer = setInterval(() => {
        if (this.countdown > 0) {
          this.countdown--;
        } else {
          // this.isDisabled = false;
          clearInterval(timer);
        }
      }, 1000);
		}
	},

	mounted() {
		this.$emit('step-change', 4)
		if (useAppStore().accountData.email?.length > 0) {
			this.email = useAppStore().accountData.email
		}

		// desde mail
		if (this.$route.query?.email?.length && this.$route.query?.valid == 'true' || this.$route.query?.email?.length && this.$route.query?.reValid == 'true' ) {
			this.onVerifyThroughLink()

			if (this.$route.query?.reValid == 'true') {
				this.reregister = true
			}
		}

		// desde accuid con cuid existente
		else if (this.$route.query?.reregister) {
			this.reregister = true
			this.verifyEmail()
		}

		// viene de clickear "olvidaste tu contraseña?"
		else if (this.$route.query?.forgotPassword) {
			this.recoverPassword = true
			this.verifyEmail()
		}

		// viene de clickear en el mail de recuperar contraseña
		else if (this.$route.query?.resetPassword) {
			this.error = false
			this.$router.push({name: 'PIN', params: {'resetPassword': true}})
		}

		// // SOLO PARA PRUEBAS MIENTRAS NO ANDE MANDAR MAILS DESDE LINODE
		// if (this.$route.query?.reregister) {
		// 	this.reregister = true
		// 	this.$router.push({query: {reValid: true}})
		// }
	}
}
</script>

<style>
/* patch para que, estando el botón de siguiente fijo en el borde inferior, no quede inaccesible el segundo input */
@media screen and (max-width: 800px) {
	.email-form {
		height: calc(100dvh - env(keyboard-inset-height, 0) - 144px);
		overflow: auto;
	}
}
</style>

<style scoped>
.body-small {
	display: flex;
	align-items: center;
	gap: 4px;
	align-self: center;
	margin-bottom: 124px;
	margin-top: auto;
}
</style>