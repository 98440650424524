<template>
	<div id="login" class="view">
		<div class="display-contents" v-if="!error">
			<h1>{{ name ? name + ', i' : 'I' }}ngresá tu clave</h1>
			<PinInputGroup
				:pinLength="pinLength"
				@valid="onValidPinEntered"
				@invalid="onInvalidPinEntered"
			/>
			<div class="btn-container flex-v">
				<!-- <router-link
					:to="{name: 'Email', query: {forgotPassword: true}}"
				> -->
				<router-link
					:to="{name: 'RecoverPassword', query: {forgotPassword: true}}"
				>
					<p>¿Olvidaste tu clave?</p>
				</router-link>
				<button
					class="btn btn-tertiary btn-log"
					@click="logout()"
				>
					Salir
				</button>
				<!-- <button
					class="btn btn-primary btn-lg"
					:disabled="!pinValid"
					@click="login()"
				>
					Siguiente
				</button> -->
			</div>
		</div>
		<div class="error flex-v" v-if="error">
			<div class="msg">
				<div class="img-container">
					<img :src="require(`@/assets/img/error.svg`)" alt="">
				</div>
				<h2>Código incorrecto</h2>
				<p>Por favor, al ingresar el código verificá que todos los caracteres sean correctos e inténtalo nuevamente.</p>
			</div>
			<div class="btn-container">
				<button class="btn btn-primary btn-lg" type="button" @click="this.error = false">Ingresar código</button>
			</div>
		</div>
	</div>
</template>

<script>
import PinInputGroup from '@/components/PinInputGroup.vue'
import { useAppStore } from '@/stores/index.js'

export default {
	name: 'LoginView',
	data() {
		return {
			name: undefined,
			pinLength: 4,
			pin: undefined,
			pinValid: false,
			error: false
		}
	},

	components: { PinInputGroup },

	methods: {
		login() {
			fetch(process.env.VUE_APP_URL_API + 'api/user/findByEmail', {
				method: 'POST',
				headers: new Headers({
					'Authorization': 'Basic '+btoa('tita_user:ef4515ee-5e13-423d-89f1-a2f4730ad346'), 
					"Content-Type": "application/json",
				}), 
				body: JSON.stringify({
					email: useAppStore().accountData.email,
					password: this.pin,
					token: useAppStore().accountData.token,
				})
			})
				.then(response => response.json())
				.then(response => this.onLogin(response))
				.catch(error => console.error('Error:', error))
		},

		logout() {
			localStorage.clear()
			window.location.reload()
		},

		onInvalidPinEntered() {
			this.pinValid = false
			this.pin = undefined
		},

		onLogin(data) {
			if (data.text == 'Contraseña incorrecta') {
				this.error = true
			}

			if (typeof data == 'object' && data.cuid?.length > 0) {
				useAppStore().setAccountProperty('name', data.name)
				useAppStore().setAccountProperty('token', data.token)
				useAppStore().setAccountProperty('userId', data.userId)
				useAppStore().setAccountProperty('email', data.email)
				useAppStore().isLoggedIn = true

				if (this.$route.query?.redirectedFrom) {
					this.$router.push(this.$route.query.redirectedFrom)
				}

				else {
					this.$router.push({name: 'home'})
				}
			}

			else {
				this.error = true
			}
		},

		onValidPinEntered(input) {
			this.pinValid = true
			this.pin = input
			this.login()
		},
	},

	mounted() {
		this.name = useAppStore().accountData.nickname
	}
}
</script>

<style scoped>
h1 {
	text-align: center;
	margin-bottom: 24px;
}

a {
	display: block;
	margin-top: auto;
	font-weight: 600;
	color: var(--color-n80);
	text-decoration: none;
	text-align: center;
}

.form {
	gap: 24px;
}

.code-glyph {
	height: 56px;
	width: 56px;
	flex-grow: 0;
	padding: 8px 18px;
	text-align: center;
	font-size: var(--type-h1-size);
	font-weight: var(--type-h1-weight);
}

.btn-text {
	border: 0;
	display: flex;
	margin: auto;
	gap: 8px;
	align-items: center;
}

.hint {
	font-size: var(--type-bxs-size);
	font-weight: var(--type-bxs-weight);
	text-align: center;
}
</style>