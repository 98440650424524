<template>
	<TopMenu
		:showNavBack="showNavBack"
		:showProgBar="showProgBar"
		:currentStep="currentStep"
		:totalSteps="5"
		v-if="showNavBack || showProgBar"
	/>
  <router-view
		@onUpdateStep="updateStep"
	/>
	<BottomNav v-if="isLoggedIn"/>
</template>

<script>
import { useAppStore } from '@/stores/index.js'
import BottomNav from '@/components/BottomNav.vue'
import TopMenu from '@/components/TopMenu.vue'

export default {
	name: 'App',
	components: {BottomNav, TopMenu},
	data() {
		return {
			user: {},
			currentStep: 0,
			route: this.$route.name
		}
	},

	computed: {
		isLoggedIn() {
			return useAppStore().isLoggedIn
		},

		showNavBack() {
			return useAppStore().showNavBack
		},

		showProgBar() {
			return (this.$route.fullPath.includes('crearcuenta')) && (!this.$route.fullPath.includes('onboarding')) && (this.$route.name !== 'CUID') && !this.$route.query?.forgotPassword
		}
	},

	methods: {
		updateStep(step) {
			this.currentStep = step
		}
	},

	created() {
	},

	mounted() {
		if ("virtualKeyboard" in navigator) {
			navigator.virtualKeyboard.overlaysContent = true
		}
	}
}
</script>

<style>
@import url(/tita-assets/dist/tita.css);
@import url(@/assets/css/titaapp.css);
</style>
